import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { graphql } from 'gatsby'

import { FormStep } from '@components/modules/FormStep'
import useForms from '@components/site/hooks/useForms'
import FormFieldsStep1 from '@components/modules/FormFieldsStep1'
import FormFieldsStep2 from '@components/modules/FormFieldsStep2'
import FormFieldsStep3 from '@components/modules/FormFieldsStep3'

type FormFieldsProps = {
  id: string
  step: string
}

// Formstep 2 is missing because it's not needed anymore, see DBNHAMMDFC-68
function FormFields({ id, step }: FormFieldsProps) {
  switch (step) {
    case '1':
      return <FormFieldsStep1 id={id} />
    case '2':
      return <FormFieldsStep2 id={id} />
    case '3':
      return <FormFieldsStep3 id={id} />
    default:
      return null
  }
}

export default function PageFormStep({
  data,
  pageContext,
  location,
}: MCDC.Contentful.IPageFormStep): ReactElement {
  const { key, step, title } = data.localPageFormStep
  const { nextStepPage } = pageContext
  const intl = useIntl()
  const { submit, optionsReferalFrom } = useForms()

  return (
    <FormStep
      title={title}
      linkTo={{
        label: nextStepPage
          ? (intl.formatMessage(
              { id: 'label.toStep' },
              { value: nextStepPage.title }
            ) as string)
          : (intl.messages['label.submit'] as string),
        onClick: () =>
          submit((submitValues) => {
            const generalInformation = {
              ...(submitValues.general_information || {}),
            }

            const backChannels = generalInformation?.back_channels?.map(
              (entry: string) =>
                entry ===
                optionsReferalFrom[optionsReferalFrom.length - 1].value
                  ? generalInformation?.back_channel_other
                  : entry
            )
            delete generalInformation.back_channel_other

            const conditions = {
              ...(submitValues.conditions || {}),
            }

            const documents =
              submitValues.documents?.map((entry: MCDC.API.UploadFile) => ({
                token: entry.token,
              })) || []

            const finalValues: MCDC.API.SubmitValues = {
              general_information: {
                ...generalInformation,
                back_channels: backChannels,
              } as any,
              skills: (submitValues.skills || {}) as any,
              conditions: conditions as any,
              documents,
              'frc-captcha-solution': submitValues[
                'frc-captcha-solution'
              ] as any,
            }
            return finalValues
          }),
      }}
    >
      <FormFields id={key} step={step} />
    </FormStep>
  )
}

export const pageQuery = graphql`
  query FormStep1ByPath($id: String!, $locale: String!) {
    localPageFormStep(id: { eq: $id }, node_locale: { eq: $locale }) {
      key
      title
      step
      label
      slug
    }
  }
`
