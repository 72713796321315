import React, { ElementType, ReactElement } from 'react'

import TextBase from '../TextBase'

export type HintProps = {
  children?: MCDC.Props.IDefault['children']
  sx?: MCDC.Props.IDefault['sx']
  label: string
}

export default function Hint({ children, sx, label }: HintProps): ReactElement {
  return (
    <TextBase sx={[sx, { p: 4, backgroundColor: 'grey.200' }]}>
      <strong>{label}: </strong>
      {children}
    </TextBase>
  )
}
