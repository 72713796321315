import React, { ReactElement } from 'react'

import { Grid, Theme } from '@mui/material'
import { useIntl } from 'react-intl'

import useForms from '@components/site/hooks/useForms'
import FormField, {
  FieldCheckboxGroupProps,
  FieldTextProps,
} from '../FormField'
import Copy from '@components/core/text/Copy'
import Hint from '@components/core/text/Hint'
import Link from '@components/core/input/Link'
import Icon from '@components/core/media/Icon'

export type FormFieldsStep3Props = {
  id: string
}

export default function FormFieldsStep3({
  id,
}: FormFieldsStep3Props): ReactElement {
  const intl = useIntl()
  const { fields, optionsYesNo, updateField } = useForms()
  const { explainApplicableNationwide } = fields

  return (
    <>
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 2,
          [theme.breakpoints.up('lg')]: { mt: 8 },
        })}
      >
        <FormField
          stepId={id}
          id="isFulltime"
          valueKey="fulltime"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.1.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="isApplicableNationwide"
          valueKey="is_applicable_nationwide"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.2.headline'],
              copy: intl.messages['applicationForm.step2.2.copy'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      {fields.isApplicableNationwide?.value === false && (
        <>
          <Grid
            item
            xs={12}
            sx={(theme: Theme) => ({
              mt: 4,
              [theme.breakpoints.up('lg')]: {
                mt: 12,
              },
            })}
          >
            <Copy>
              <strong>
                {intl.messages['applicationForm.step2.3.headline']}{' '}
              </strong>
              {intl.messages['applicationForm.step2.3.copy']}
            </Copy>
          </Grid>
          <Grid item xs={12}>
            <FormField
              stepId={id}
              id="explainApplicableNationwide"
              valueKey="explain_applicable_nationwide"
              type="textArray"
              isRequired
              isConditional
              props={
                {
                  placeholder: 'label.countryNumber',
                } as FieldTextProps
              }
            />
          </Grid>
          {explainApplicableNationwide?.value.length < 3 && (
            <Grid item xs={12}>
              <Link
                variant="navigation"
                onClick={() => {
                  updateField({
                    id: 'explainApplicableNationwide',
                    stepId: id,
                    value: [...(explainApplicableNationwide?.value || []), ''],
                  })
                }}
              >
                {intl.messages['label.addLand']}
                <Icon sx={{ color: 'primary.main' }} variant="add" />
              </Link>
            </Grid>
          )}
          <Grid item xs={12}>
            <Hint label={intl.messages['label.hint'] as string}>
              {intl.messages['applicationForm.step2.2.hint']}
            </Hint>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="hasExperienceWithMcdSystem"
          valueKey="has_experience_with_mcd_system"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.4.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      {fields.hasExperienceWithMcdSystem?.value && (
        <Grid item xs={12}>
          <FormField
            stepId={id}
            id="explainExperienceWithMcdSystem"
            valueKey="explain_experience_with_mcd_system"
            type="text"
            isRequired
            isConditional
            props={
              {
                placeholder: intl.messages['label.systemDurationPosition'],
              } as FieldTextProps
            }
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="isWorkingForMcd"
          valueKey="is_working_for_mcd"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.5.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      {fields.isWorkingForMcd?.value && (
        <Grid item xs={12}>
          <FormField
            stepId={id}
            id="explainWorkingForMcd"
            valueKey="explain_working_for_mcd"
            type="text"
            isRequired
            isConditional
            props={
              {
                multiline: true,
                rows: 4,
                placeholder: intl.messages['label.detailedDescription'],
              } as FieldTextProps
            }
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="hasHealthImpairments"
          valueKey="has_health_impairments"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.6.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Hint label={intl.messages['label.hint'] as string}>
          {intl.messages['applicationForm.step2.6.hint']}
        </Hint>
      </Grid>

      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="hasGermanResidencePermit"
          valueKey="has_german_residence_permit"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.7.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="hasGermanSkills"
          valueKey="has_german_skills"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.8.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="isAssessmentCentreReady"
          valueKey="is_assessment_centre_ready"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.9.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="hasCriminalRecords"
          valueKey="has_criminal_records"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.10.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Hint label={intl.messages['label.hint'] as string}>
          {intl.messages['applicationForm.step2.10.hint']}
        </Hint>
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="hasExperienceWithFranchise"
          valueKey="has_experience_with_franchise"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.11.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      {fields.hasExperienceWithFranchise?.value && (
        <Grid item xs={12}>
          <FormField
            stepId={id}
            id="explainExperienceWithFranchise"
            valueKey="explain_experience_with_franchise"
            type="text"
            isRequired
            isConditional
            props={
              {
                multiline: true,
                rows: 4,
                placeholder: intl.messages['label.systemExperience'],
              } as FieldTextProps
            }
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="hasEmployerExperience"
          valueKey="has_staff_responsibility"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step2.12.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <Copy>
          <strong>{intl.messages['applicationForm.step2.13.headline']} </strong>
        </Copy>
      </Grid>
      <Grid item xs={12}>
        <FormField
          stepId={id}
          id="importantSkills"
          valueKey="important_skills"
          type="text"
          isRequired
          props={
            {
              multiline: true,
              rows: 8,
              placeholder: intl.messages['label.yourAnswer'],
            } as FieldTextProps
          }
        />
      </Grid>
    </>
  )
}
