import Section from '@components/core/container/Section'
import Button from '@components/core/input/Button'
import Headline from '@components/core/text/Headline'
import { Box, Container, Grid, Theme } from '@mui/material'
import React from 'react'

export type FormStepProps = {
  title: string
  linkTo: MCDC.Props.IButtonTo
  rowSpacing?: number
  columnSpacing?: number
  sx?: MCDC.Props.IDefault['sx']
  children: MCDC.Props.IDefault['children']
}

export function FormStep({
  title,
  linkTo,
  rowSpacing = 4,
  columnSpacing = 4,
  children,
  sx,
}: FormStepProps) {
  return (
    <Section theme="medium" maxWidth="sm">
      <Grid
        container
        rowSpacing={rowSpacing}
        columnSpacing={columnSpacing}
        sx={sx}
      >
        <Grid item xs={12}>
          <Headline variant="h4" sx={{ mb: 2 }}>
            {title}
          </Headline>
        </Grid>
        {children}
      </Grid>
      <Box sx={{ mt: 16 }}>
        <Button
          color="primary"
          isDisabled={linkTo.isDisabled}
          onClick={linkTo.onClick}
          sx={(theme: Theme) => ({
            [theme.breakpoints.down('md')]: {
              width: '100%',
            },
          })}
        >
          {linkTo.label}
        </Button>
      </Box>
    </Section>
  )
}
