import React, { ReactElement } from 'react'

import { Grid, Theme } from '@mui/material'
import { useIntl } from 'react-intl'

import Headline from '@components/core/text/Headline'
import FormField, {
  FieldCheckboxGroupProps,
  FieldTextProps,
} from '@components/modules/FormField'
import useForms from '@components/site/hooks/useForms'

export type FormFieldsStep1Props = {
  id: string
}

export default function FormFieldsStep1({
  id,
}: FormFieldsStep1Props): ReactElement {
  const intl = useIntl()
  const { fields, optionsReferalFrom, optionsSex } = useForms()
  return (
    <>
      <Grid item xs={12}>
        <FormField
          stepId={id}
          id="sex"
          valueKey="sex"
          type="radioGroup"
          isRequired
          isConditional
          props={
            {
              options: optionsSex,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          stepId={id}
          id="firstname"
          valueKey="firstname"
          type="text"
          isRequired
          props={
            {
              label: intl.messages['label.firstname'],
            } as FieldTextProps
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          stepId={id}
          id="surname"
          valueKey="surname"
          type="text"
          isRequired
          props={
            {
              label: intl.messages['label.surname'],
            } as FieldTextProps
          }
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormField
          stepId={id}
          id="street"
          valueKey="street"
          type="text"
          isRequired
          props={
            {
              label: intl.messages['label.street'],
            } as FieldTextProps
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormField
          stepId={id}
          id="streetNumber"
          valueKey="street_number"
          type="text"
          isRequired
          props={
            {
              label: intl.messages['label.streetNumber'],
            } as FieldTextProps
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormField
          stepId={id}
          id="zip"
          valueKey="zip"
          type="text"
          isRequired
          props={
            {
              label: intl.messages['label.zipcode'],
            } as FieldTextProps
          }
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormField
          stepId={id}
          id="city"
          valueKey="city"
          type="text"
          isRequired
          props={
            {
              label: intl.messages['label.city'],
            } as FieldTextProps
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          stepId={id}
          id="email"
          valueKey="email"
          type="text"
          isRequired
          props={
            {
              label: intl.messages['label.email'],
            } as FieldTextProps
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          stepId={id}
          id="birthday"
          valueKey="date_of_birth"
          type="datePicker"
          isRequired
          props={
            {
              label: intl.messages['label.birthday'],
            } as FieldTextProps
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormField
          stepId={id}
          id="phone"
          valueKey="phone"
          type="text"
          isRequired
          props={
            {
              label: intl.messages['label.phone'],
            } as FieldTextProps
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Headline
          variant="h5"
          sx={(theme: Theme) => ({
            mt: 8,
            mb: 4,
            [theme.breakpoints.up('lg')]: {
              mt: 12,
              mb: 6,
            },
          })}
        >
          {intl.messages['applicationForm.step1.2.headline']}
        </Headline>
        <FormField
          stepId={id}
          id="backChannel"
          valueKey="back_channels"
          type="checkboxGroup"
          isRequired
          props={
            {
              options: optionsReferalFrom,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      {fields.backChannel?.value?.includes(
        optionsReferalFrom[optionsReferalFrom.length - 1].value
      ) && (
        <Grid item xs={12}>
          <FormField
            stepId={id}
            id="backChannelOther"
            valueKey="back_channel_other"
            type="text"
            isRequired
            isConditional
            props={
              {
                placeholder: intl.messages['label.pleaseDescribe'],
              } as FieldTextProps
            }
          />
        </Grid>
      )}
    </>
  )
}
