import React, { ReactElement } from 'react'

import { Grid, Theme } from '@mui/material'
import { useIntl } from 'react-intl'

import useForms from '@components/site/hooks/useForms'
import FormField, {
  FieldCheckboxGroupProps,
  FieldTextProps,
} from '../FormField'
import Copy from '@components/core/text/Copy'
import Headline from '@components/core/text/Headline'
import Hint from '@components/core/text/Hint'

export type FormFieldsStep4Props = {
  id: string
}

export default function FormFieldsStep4({
  id,
}: FormFieldsStep4Props): ReactElement {
  const intl = useIntl()
  const { fields, optionsYesNo } = useForms()

  return (
    <>
      <Grid item xs={12} sx={{ mt: 8 }}>
        <FormField
          stepId={id}
          id="has_bankruptcy_proceedings"
          valueKey="has_bankruptcy_proceedings"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step3.0.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 8 }}>
        <FormField
          stepId={id}
          id="hasEquity"
          valueKey="has_equity"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step3.1.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      {fields.hasEquity?.value && (
        <>
          <Grid
            item
            xs={12}
            sx={(theme: Theme) => ({
              mt: 4,
              [theme.breakpoints.up('lg')]: {
                mt: 12,
              },
            })}
          >
            <Copy>
              <strong>
                {intl.messages['applicationForm.step3.2.headline']}{' '}
              </strong>
            </Copy>
          </Grid>

          <Grid item xs={12}>
            <FormField
              stepId={id}
              id="equity"
              valueKey="equity"
              type="text"
              isRequired
              isConditional
              props={
                {
                  placeholder: intl.messages['label.equityInput'],
                } as FieldTextProps
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Hint label={intl.messages['label.hint'] as string}>
              {intl.messages['applicationForm.step3.2.hint']}
            </Hint>
          </Grid>
        </>
      )}
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          stepId={id}
          id="hasSupportObligations"
          valueKey="has_support_obligations"
          type="radioGroup"
          isRequired
          props={
            {
              label: intl.messages['applicationForm.step3.3.headline'],
              options: optionsYesNo,
            } as FieldCheckboxGroupProps
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <Headline variant="h5">
          <strong>{intl.messages['applicationForm.step3.4.headline']} </strong>
        </Headline>
      </Grid>
      <Grid item xs={12}>
        <Copy>
          <strong>{intl.messages['applicationForm.step3.4.copy']} </strong>
        </Copy>
      </Grid>
      <Grid item xs={12}>
        <Hint label={intl.messages['label.hint'] as string}>
          {intl.messages['applicationForm.step3.4.hint']}
        </Hint>
      </Grid>
      <Grid item xs={12}>
        <FormField
          stepId={id}
          id="references"
          valueKey="references"
          type="text"
          isRequired
          props={
            {
              multiline: true,
              rows: 8,
              placeholder: intl.messages['label.yourAnswer'],
            } as FieldTextProps
          }
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <Headline variant="h5">
          <strong>{intl.messages['applicationForm.step3.5.headline']} </strong>
        </Headline>
      </Grid>
      <Grid item xs={12}>
        <Copy>{intl.messages['applicationForm.step3.5.copy']}</Copy>
      </Grid>
      <Grid item xs={12}>
        <Copy component="ul">
          <li>{intl.messages['label.photo']}</li>
          <li>{intl.messages['label.cv']}</li>
          <li>{intl.messages['label.motivation']}</li>
        </Copy>
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <FormField
          id="documents"
          valueKey="documents"
          type="documents"
          isRequired
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={(theme: Theme) => ({
          mt: 4,
          [theme.breakpoints.up('lg')]: {
            mt: 12,
          },
        })}
      >
        <Copy isHtml>{intl.messages['applicationForm.step3.6.copy']}</Copy>
      </Grid>
      <Grid item xs={12}>
        <FormField
          id="freindlyCapture"
          valueKey="frc-captcha-solution"
          type="capture"
          isRequired
        />
      </Grid>
    </>
  )
}
